import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import {
  DownloadSearchFeedback,
  DownloadSearchFeedbackR,
  GetOneSearchFeedback,
  GetSearchFeedback,
  TypeSearchFeedbackR,
  TypOneSearchFeedbackR,
} from './actions';
import ActionTypes, { TSearchFeedbackState } from './types';
import { callApi } from '../../utils/callApi';

function* getSearchFeedbackWorker(action: ReturnType<typeof GetSearchFeedback.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchFeedbackR;

  let success = true;
  const query = buildQuery(data);
  let resp: TSearchFeedbackState['data'] | null = null;

  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/search-feedback?${query}`,
    })) as TSearchFeedbackState['data'];
    yield put(GetSearchFeedback.success(resp));
  } catch (e) {
    success = false;
    yield put(GetSearchFeedback.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* getOneSearchFeedbackWorker(action: ReturnType<typeof GetOneSearchFeedback.request>): Generator {
  const { data, callBack } = action.payload as TypOneSearchFeedbackR;

  let success = true;
  let resp: TSearchFeedbackState['searchFeedback'] | null = null;

  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/search-feedback/one/${data.id}`,
    })) as TSearchFeedbackState['searchFeedback'];
    yield put(GetOneSearchFeedback.success(resp ? resp : null));
  } catch (e) {
    success = false;
    yield put(GetOneSearchFeedback.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* downloadSearchFeedbackWorker(action: ReturnType<typeof DownloadSearchFeedback.request>): Generator {
  const { data, callBack } = action.payload as DownloadSearchFeedbackR;

  let success = true;
  const query = buildQuery(data);
  let resp: Buffer | null = null;

  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/search-feedback/download-csv?${query}`,
      isBlobResponseType: true,
    })) as Buffer;
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `searchFeedback.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    yield put(DownloadSearchFeedback.success(!!resp));
  } catch (e) {
    success = false;
    yield put(DownloadSearchFeedback.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_SEARCH_FEEDBACK_R, getSearchFeedbackWorker);
  yield takeEvery(ActionTypes.GET_ONE_SEARCH_FEEDBACK_R, getOneSearchFeedbackWorker);
  yield takeEvery(ActionTypes.DOWNLOAD_SEARCH_FEEDBACK_R, downloadSearchFeedbackWorker);
}

export default function* searchFeedbackSaga() {
  yield all([fork(watchFetchRequest)]);
}
