import { Reducer } from 'redux';
import ActionTypes, { TSearchFeedbackState } from './types';

const initialState: TSearchFeedbackState = {
  loading: false,
  error: undefined,
  data: null,
  searchFeedback: null,
};

const reducer: Reducer<TSearchFeedbackState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SEARCH_FEEDBACK_R:
    case ActionTypes.GET_ONE_SEARCH_FEEDBACK_R:
    case ActionTypes.DOWNLOAD_SEARCH_FEEDBACK_R: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }

    case ActionTypes.GET_SEARCH_FEEDBACK_S: {
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload,
      };
    }

    case ActionTypes.GET_ONE_SEARCH_FEEDBACK_S: {
      return {
        ...state,
        loading: false,
        error: undefined,
        searchFeedback: action.payload,
      };
    }

    case ActionTypes.DOWNLOAD_SEARCH_FEEDBACK_S: {
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    }

    case ActionTypes.GET_SEARCH_FEEDBACK_E:
    case ActionTypes.GET_ONE_SEARCH_FEEDBACK_E:
    case ActionTypes.DOWNLOAD_SEARCH_FEEDBACK_E: {
      return { ...state, loading: false, error: action.payload };
    }

    default:
      return state;
  }
};

export { reducer as SearchFeedbackReducer };
