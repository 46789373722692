import { combineReducers } from 'redux';
import { SidebarReducer } from './sidebar/reducer';
import { ConfigurationsReducer } from './configurations/reducer';
import { UserReducer } from './user/reducer';
import { UsersReducer } from './users/reducer';
import { RubricsReducer } from './rubrics/reducer';
import { VideoReducer } from './video/reducer';
import { FormsReducer } from './forms/reducer';
import { AuthorsReducer } from './authors/reducer';
import { ConsultationsReducer } from './consultations/reducer';
import { BlocksReducer } from './blocks/reducer';
import { MainReducer } from './main/reducer';
import { OfficialDocReducer } from './officialDoc/reducer';
import { OfficialDocBlocksReducer } from './officialDocBlocks/reducer';
import { PublishersReducer } from './publishers/reducer';
import { OfficialDocFilesReducer } from './officialDocFiles/reducer';
import { ConfiguratorReducer } from './configurator/reducer';
import { FactoidsReducer } from './factoids/reducer';
import { EditorsReducer } from './editors/reducer';
import { FormFilesReducer } from './formFiles/reducer';
import { CartReducer } from './cart/reducer';
import { CataloguesReducer } from './catalogues/reducer';
import { SelectionsReducer } from './selections/reducer';
import { WidgetsReducer } from './widgets/reducer';
import { RatingsReducer } from './ratings/reducer';
import { CalendarReducer } from './calendar/reducer';
import { MainInMonthReducer } from './mainInMonth/reducer';
import { ServicesReducer } from './services/reducer';
import { ProductRatingsReducer } from './productRatings/reducer';
import { DefinitionsReducer } from './definition/reducer';
import { OfficialDocDraftsReducer } from './officialDocDrafts/reducer';
import { SearchFeedbackReducer } from './searchFeedback/reducer';

const rootReducer = combineReducers({
  Sidebar: SidebarReducer,
  Configurations: ConfigurationsReducer,
  User: UserReducer,
  Users: UsersReducer,
  Editors: EditorsReducer,
  Rubrics: RubricsReducer,
  Video: VideoReducer,
  Forms: FormsReducer,
  FormFiles: FormFilesReducer,
  Authors: AuthorsReducer,
  Consultations: ConsultationsReducer,
  Blocks: BlocksReducer,
  Main: MainReducer,
  OfficialDoc: OfficialDocReducer,
  OfficialDocDrafts: OfficialDocDraftsReducer,
  OfficialDocBlocks: OfficialDocBlocksReducer,
  OfficialDocFiles: OfficialDocFilesReducer,
  Publishers: PublishersReducer,
  Configurator: ConfiguratorReducer,
  Factoids: FactoidsReducer,
  Cart: CartReducer,
  Catalogues: CataloguesReducer,
  Selections: SelectionsReducer,
  Widgetes: WidgetsReducer,
  Ratings: RatingsReducer,
  Calendar: CalendarReducer,
  MainInMonth: MainInMonthReducer,
  Services: ServicesReducer,
  ProductRatings: ProductRatingsReducer,
  Definitions: DefinitionsReducer,
  SearchFeedback: SearchFeedbackReducer,
});

export default rootReducer;
