export interface TSearchFeedbackState {
  loading: boolean;
  error: string | undefined;
  data: { searchFeedback: ISearchFeedback[]; page: number; pages: number; total: number } | null;
  searchFeedback: ISearchFeedback | null;
}

export interface ISearchFeedback {
  id: number;
  site_id: number;
  client_id: number;
  product_id: number;
  search_feedback_response_id: number;
  access_type_id: number;
  fio: string;
  email: string;
  phone: string;
  feedback_text: string;
  search_query: string;
  search_url: string;
  created_at: Date;
  updated_at: Date;
  search_feedback_response?: { name: string };
  access_type?: { name: string };
}

enum ActionTypes {
  GET_SEARCH_FEEDBACK_R = '@@searchFeedback/GET_SEARCH_FEEDBACK_R',
  GET_SEARCH_FEEDBACK_S = '@@searchFeedback/GET_SEARCH_FEEDBACK_S',
  GET_SEARCH_FEEDBACK_E = '@@searchFeedback/GET_SEARCH_FEEDBACK_E',

  GET_ONE_SEARCH_FEEDBACK_R = '@@searchFeedback/GET_ONE_SEARCH_FEEDBACK_R',
  GET_ONE_SEARCH_FEEDBACK_S = '@@searchFeedback/GET_ONE_SEARCH_FEEDBACK_S',
  GET_ONE_SEARCH_FEEDBACK_E = '@@searchFeedback/GET_ONE_SEARCH_FEEDBACK_E',

  DOWNLOAD_SEARCH_FEEDBACK_R = '@@searchFeedback/DOWNLOAD_SEARCH_FEEDBACK_R',
  DOWNLOAD_SEARCH_FEEDBACK_S = '@@searchFeedback/DOWNLOAD_SEARCH_FEEDBACK_S',
  DOWNLOAD_SEARCH_FEEDBACK_E = '@@searchFeedback/DOWNLOAD_SEARCH_FEEDBACK_E',
}

export default ActionTypes;
