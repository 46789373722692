import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TSearchFeedbackState } from './types';

const GetSearchFeedbackAction = new Actions('GET_SEARCH_FEEDBACK', ActionTypes);
export interface TypeSearchFeedbackR extends TDefRequest {
  data: {
    page?: number;
    site_id: number;
    access_type_id?: number;
    search_feedback_response_id?: number;
    client_id?: number;
    from?: string;
    to?: string;
  };
}
export const GetSearchFeedback = {
  request: (payload: TypeSearchFeedbackR) => GetSearchFeedbackAction.request(payload),
  success: (payload: TSearchFeedbackState['data']) => GetSearchFeedbackAction.success(payload || {}),
  error: (message: string) => GetSearchFeedbackAction.error(message),
};

const GetOneSearchFeedbackAction = new Actions('GET_ONE_SEARCH_FEEDBACK', ActionTypes);
export interface TypOneSearchFeedbackR extends TDefRequest {
  data: {
    id: number;
  };
}
export const GetOneSearchFeedback = {
  request: (payload: TypOneSearchFeedbackR) => GetOneSearchFeedbackAction.request(payload),
  success: (payload: TSearchFeedbackState['searchFeedback']) => GetOneSearchFeedbackAction.success(payload || {}),
  error: (message: string) => GetOneSearchFeedbackAction.error(message),
};

const DownloadSearchFeedbackAction = new Actions('DOWNLOAD_SEARCH_FEEDBACK', ActionTypes);
export interface DownloadSearchFeedbackR extends TDefRequest {
  data: {
    page?: number;
    site_id: number;
    access_type_id?: number;
    search_feedback_response_id?: number;
    client_id?: number;
    from?: string;
    to?: string;
  };
}
export const DownloadSearchFeedback = {
  request: (payload: DownloadSearchFeedbackR) => DownloadSearchFeedbackAction.request(payload),
  success: (payload: boolean) => DownloadSearchFeedbackAction.success(payload || {}),
  error: (message: string) => DownloadSearchFeedbackAction.error(message),
};
