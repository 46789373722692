import React from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { AppStore } from '../../store/applicationState';
import { useDispatch, useSelector } from 'react-redux';
import { Roles } from '../../utils/consts';
import { GetOneSearchFeedback } from '../../store/searchFeedback/actions';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { format } from 'date-fns';

export const ReviewSearchFeedback: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { SearchFeedback, User } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (id) {
      dispatch(GetOneSearchFeedback.request({ data: { id: Number(id) } }));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    if (SearchFeedback.searchFeedback) {
      dispatch(ConfigurationsActions.setSiteId(SearchFeedback.searchFeedback.site_id));
    }
  }, [dispatch, SearchFeedback.searchFeedback]);

  if (![Roles.HEAD, Roles.ADMIN].includes(User.data?.role || 0) || !User.data?.is_raiting) {
    return null;
  }

  if (!SearchFeedback.searchFeedback) return <Box>Дані не знайдено</Box>;

  const {
    created_at,
    search_query,
    search_url,
    search_feedback_response,
    feedback_text,
    access_type,
    client_id,
    fio,
    phone,
    email,
  } = SearchFeedback.searchFeedback;

  return (
    <Stack gap={2}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography component='h2' variant='h6'>
          ОЦІНКА ПОШУКУ
        </Typography>
        <Button variant='contained' color='inherit' onClick={() => navigate(-1)}>
          Повернутися
        </Button>
      </Stack>
      <TextField
        disabled
        size='small'
        variant='outlined'
        label='Дата'
        value={format(new Date(String(created_at)), 'dd.MM.yyyy HH.mm')}
      />
      <TextField disabled size='small' variant='outlined' label='Пошуковий запит' multiline value={search_query} />
      <TextField
        disabled
        size='small'
        variant='outlined'
        label='URL сторінки пошукової видачі'
        multiline
        value={search_url}
      />
      <TextField
        disabled
        size='small'
        variant='outlined'
        label='Відповідь'
        value={search_feedback_response?.name || ''}
      />
      <TextField disabled size='small' variant='outlined' label='Відгук' multiline value={feedback_text} />
      <TextField disabled size='small' variant='outlined' label='Доступ' value={access_type?.name} />
      <TextField disabled size='small' variant='outlined' label='ID користувача' value={client_id || ''} />
      <TextField disabled size='small' variant='outlined' label='ПІБ користувача' value={fio} />
      <TextField disabled size='small' variant='outlined' label='Телефон користувача' value={phone} />
      <TextField disabled size='small' variant='outlined' label='Е-mail користувача' value={email} />
    </Stack>
  );
};
