import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import { AppStore } from '../../store/applicationState';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { format } from 'date-fns';
import { ROUTES } from '../../utils/routes';
import { StylesTable } from '../../components/Stylestable';
import { BasicDatePicker, SelectOutline } from '../../components/ui';
import { StylesRatings } from '../../components/Ratings/StylesMark';
import { IconButton, Pagination, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DownloadSearchFeedback, GetSearchFeedback } from '../../store/searchFeedback/actions';
import { BaseModal } from '../../components/modals';

interface Column {
  id: string;
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'created_at', label: 'Дата', align: 'center', width: '10%' },
  { id: 'search_url', label: 'Пошуковий запит', align: 'center', width: '20%' },
  { id: 'search_feedback_response_id', label: 'Відповідь', align: 'center', width: '20%' },
  { id: 'feedback_text', label: 'Відгук', align: 'center', width: '30%' },
  { id: 'access_type_id', label: 'Доступ', align: 'center', width: '10%' },
  { id: 'review', label: 'Переглянути', align: 'center', width: '10%' },
];

export const SearchFeedbackList: React.FC = () => {
  const [from, setFrom] = React.useState<null | Date>(null);
  const [to, setTo] = React.useState<null | Date>(null);
  const [responseId, setResponseId] = React.useState('');
  const [accessTypeId, setAccessTypeId] = React.useState('');
  const [clientId, setClientId] = React.useState('');
  const [textForMoadal, setTextForMoadal] = React.useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stylesRatings: any = StylesRatings();
  const classesTable = StylesTable();

  const { Configurations, SearchFeedback } = useSelector((store: AppStore) => store);

  React.useEffect(() => {
    dispatch(
      GetSearchFeedback.request({
        data: {
          site_id: Configurations.siteId,
          page: 1,
        },
      })
    );
  }, [dispatch, Configurations.siteId]);

  const resetState = () => {
    if (SearchFeedback.loading) return;

    setFrom(null);
    setTo(null);
    setResponseId('');
    setAccessTypeId('');
    setClientId('');

    dispatch(
      GetSearchFeedback.request({
        data: {
          site_id: Configurations.siteId,
          page: 1,
        },
      })
    );
  };

  const handleSearch = (value?: number) => {
    if (SearchFeedback.loading) return;

    dispatch(
      GetSearchFeedback.request({
        data: {
          site_id: Configurations.siteId,
          from: from?.toISOString() || undefined,
          to: to?.toISOString() || undefined,
          search_feedback_response_id: responseId ? Number(responseId) : undefined,
          access_type_id: accessTypeId ? Number(accessTypeId) : undefined,
          client_id: clientId ? Number(clientId) : undefined,
          page: value,
        },
      })
    );
  };

  const handleChangePage = (event: unknown, page: number) => {
    if (SearchFeedback.loading) return;
    if (!SearchFeedback.data) return;
    if (SearchFeedback.data.pages >= page) {
      handleSearch(page);
    }
  };

  const handleTextForMoadal = (value: string) => {
    return () => setTextForMoadal(value);
  };

  const handleChangeRoute =
    (query = '' as string | number) =>
    () => {
      navigate(ROUTES.routeChange(`${ROUTES.searchFeedback}/${query}?site_id=${Configurations.siteId}`));
    };

  const handleDownloadFile = () => {
    if (SearchFeedback.loading) return;

    dispatch(
      DownloadSearchFeedback.request({
        data: {
          site_id: Configurations.siteId,
          from: from?.toISOString() || undefined,
          to: to?.toISOString() || undefined,
          search_feedback_response_id: responseId ? Number(responseId) : undefined,
          access_type_id: accessTypeId ? Number(accessTypeId) : undefined,
          client_id: clientId ? Number(clientId) : undefined,
        },
      })
    );
  };

  return (
    <>
      <Box className={stylesRatings.header}>
        <Button variant='contained' color='primary' disabled={SearchFeedback.loading} onClick={handleDownloadFile}>
          Скачати
        </Button>
        <BasicDatePicker style={{ width: '150px' }} label='Дата з' value={from} onChange={setFrom} />
        <BasicDatePicker style={{ width: '150px' }} label={'Дата по'} value={to} onChange={setTo} />
        <SelectOutline
          style={{ width: '150px' }}
          title='Відповідь'
          value={responseId}
          options={[
            { value: '', label: 'усі' },
            { value: '1', label: 'клік на «хрестик»' },
            { value: '2', label: 'вони повністю відповідають запиту' },
            { value: '3', label: 'серед них багато зайвого' },
            { value: '4', label: 'вони не відповідають запиту' },
            { value: '5', label: 'інше' },
          ]}
          handleChange={setResponseId}
        />
        <SelectOutline
          style={{ width: '150px' }}
          title='Доступ'
          value={accessTypeId}
          options={[
            { value: '', label: 'усі' },
            { value: '1', label: 'передплатник' },
            { value: '2', label: 'демник' },
            { value: '3', label: 'авторизований' },
            { value: '4', label: 'співробітник' },
            { value: '5', label: 'неавторизований' },
          ]}
          handleChange={setAccessTypeId}
        />
        <TextField
          sx={{ width: '150px' }}
          size='small'
          label='Пошук за ID'
          variant='outlined'
          value={clientId}
          onChange={(e) => setClientId(e.target.value)}
        />
        <Button variant='contained' color='primary' disabled={SearchFeedback.loading} onClick={() => handleSearch()}>
          Пошук
        </Button>
        <Button variant='contained' color='primary' disabled={SearchFeedback.loading} onClick={resetState}>
          Очистити фільтри
        </Button>
        <div className={stylesRatings.total}>Усього: {SearchFeedback.data?.total || 0}</div>
        {SearchFeedback.loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {SearchFeedback.data?.searchFeedback.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row?.id}`}>
                  <TableCell align='center'>{format(new Date(String(row.created_at)), 'dd.MM.yyyy HH.mm')}</TableCell>
                  <TableCell
                    align='center'
                    className={classesTable.row}
                    sx={{ cursor: 'pointer' }}
                    onClick={handleTextForMoadal(row.search_query)}
                  >
                    {row.search_query}
                  </TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.search_feedback_response?.name || ''}
                  </TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.feedback_text}
                  </TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    {row.access_type?.name || ''}
                  </TableCell>
                  <TableCell align='center' className={classesTable.row}>
                    <IconButton onClick={handleChangeRoute(row.id)}>
                      <RemoveRedEyeOutlinedIcon color='inherit' className={stylesRatings.iconDetail} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classesTable.pagination}
        count={SearchFeedback.data?.pages || 1}
        page={SearchFeedback.data ? SearchFeedback.data.page : 1}
        onChange={handleChangePage}
        disabled={SearchFeedback.loading}
      />
      <BaseModal
        open={!!textForMoadal}
        title={'Пошуковий запит'}
        children={<>{textForMoadal}</>}
        onCancel={handleTextForMoadal('')}
        btnNameCancel='Закрити'
      />
    </>
  );
};
