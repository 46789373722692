import { fork } from 'redux-saga/effects';
import userSaga from './user/sagas';
import usersSaga from './users/sagas';
import configurationsSaga from './configurations/sagas';
import rubricsSaga from './rubrics/sagas';
import videoSaga from './video/sagas';
import formsSaga from './forms/sagas';
import formFilesSaga from './formFiles/sagas';
import authorsSaga from './authors/sagas';
import consultationsSaga from './consultations/sagas';
import blocksSaga from './blocks/sagas';
import mainSaga from './main/sagas';
import officialDocSaga from './officialDoc/sagas';
import officialDocBlocksSaga from './officialDocBlocks/sagas';
import publishersSaga from './publishers/sagas';
import officialDocFilesSaga from './officialDocFiles/sagas';
import configuratorSaga from './configurator/sagas';
import factoidsSaga from './factoids/sagas';
import editorsSaga from './editors/sagas';
import cartSaga from './cart/sagas';
import cataloguesSaga from './catalogues/sagas';
import selectionsSaga from './selections/sagas';
import widgetesSaga from './widgets/sagas';
import ratingsSaga from './ratings/sagas';
import CalendarSaga from './calendar/sagas';
import MainInMonthSaga from './mainInMonth/sagas';
import ServicesSaga from './services/sagas';
import productRatingsSaga from './productRatings/sagas';
import definitionsSaga from './definition/sagas';
import officialDocDraftsSaga from './officialDocDrafts/sagas';
import searchFeedbackSaga from './searchFeedback/sagas';

export default function* Main() {
  yield fork(userSaga);
  yield fork(usersSaga);
  yield fork(editorsSaga);
  yield fork(configurationsSaga);
  yield fork(rubricsSaga);
  yield fork(videoSaga);
  yield fork(formsSaga);
  yield fork(formFilesSaga);
  yield fork(authorsSaga);
  yield fork(consultationsSaga);
  yield fork(blocksSaga);
  yield fork(mainSaga);
  yield fork(officialDocSaga);
  yield fork(officialDocDraftsSaga);
  yield fork(officialDocBlocksSaga);
  yield fork(officialDocFilesSaga);
  yield fork(publishersSaga);
  yield fork(configuratorSaga);
  yield fork(factoidsSaga);
  yield fork(cartSaga);
  yield fork(cataloguesSaga);
  yield fork(selectionsSaga);
  yield fork(widgetesSaga);
  yield fork(ratingsSaga);
  yield fork(CalendarSaga);
  yield fork(MainInMonthSaga);
  yield fork(ServicesSaga);
  yield fork(productRatingsSaga);
  yield fork(definitionsSaga);
  yield fork(searchFeedbackSaga);
}
